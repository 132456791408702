import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Category from '../../components/Category'
import Meta from '../../components/Meta'
import { listProducts } from '../../actions/productActions'
const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
      <Meta />
      <>
        <h1 className="text-center">Shop Now</h1>
        <p className="text-center">
          Pay cash and save up to 60% on common medical services
        </p>

        <Row>
          <Col sm={12} md={6} lg={4}>
            <Category category={'lab'} price={149} image={'/images/lab.jpg'} />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Category
              category={'imaging'}
              price={349}
              image={'/images/imaging.jpg'}
            />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Category
              category={'colonoscopy'}
              price={2999}
              image={'/images/colonoscopy.jpg'}
            />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Category
              category={'physical-therapy'}
              price={149}
              image={'/images/physical-therapy.jpg'}
            />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Category
              category={'physician-visit'}
              price={199}
              image={'/images/physician-visit.jpg'}
            />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Category
              category={'direct-primary-care'}
              price={99}
              image={'/images/direct-primary-care.jpg'}
            />
          </Col>
        </Row>
      </>
    </>
  )
}

export default HomeScreen
