import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../components/FormContainer'
import CheckoutSteps from '../../components/CheckoutSteps'
import { saveBillingAddress } from '../../actions/cartActions'

const BillingScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)

  const { billingAddress } = cart
  const [address1, setAddress1] = useState(billingAddress.address1)
  const [address2, setAddress2] = useState(billingAddress.address2)
  const [city, setCity] = useState(billingAddress.city)
  const [state, setState] = useState(billingAddress.state)
  const [postalCode, setPostalCode] = useState(billingAddress.postalCode)
  const [country, setCountry] = useState(billingAddress.country)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      saveBillingAddress({
        address1,
        address2,
        city,
        state,
        postalCode,
        country,
      })
    )
    history.push('/payment')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Billing</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="address1">
          <Form.Label>Address 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={address1}
            required
            onChange={(e) => setAddress1(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="address2">
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Apt, Unit, etc"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="state">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter state"
            value={state}
            required
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="postalCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter postal code"
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          Continue
        </Button>
      </Form>
    </FormContainer>
  )
}

export default BillingScreen
