import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <Container bg="dark" variant="dark">
        <Row>
          <Col className="text-center pt-5">
            Copyright &copy; Offshore Ventures
          </Col>
        </Row>
        <Row>
          <Col className="text-center"></Col>
        </Row>
        <Row>
          <Col className="text-center pb-4">
            <a href="mailto:info@everyonehealth.com">
              info@offshoreventures.com
            </a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="text-center py-4">
            <Link to={`/login?redirect=become-a-provider`}>
              Become a Provider
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
