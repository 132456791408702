import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <Nav className="justify-content-center mb-4 d-flex justify-content-between">
      <Nav.Item>
        {step1 ? (
          <LinkContainer to="/login">
            <Nav.Link className="px-1">Sign In</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className="px-1">
            Sign In
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/billing">
            <Nav.Link className="px-1">Billing</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className="px-1">
            Billing
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link className="px-1">Payment</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className="px-1">
            Payment
          </Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/placeorder">
            <Nav.Link className="px-1">Place Order</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled className="px-1">
            Place Order
          </Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps
