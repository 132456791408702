import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Currency from '../../components/Currency'
import {
  getProviderOrderDetails,
  renderOrderService,
} from '../../actions/orderActions'

const ProviderOrderDetailsScreen = ({ match, history }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  const orderDetailsProvider = useSelector(
    (state) => state.orderDetailsProvider
  )
  const { order, loading, error } = orderDetailsProvider

  const providerDetails = useSelector((state) => state.providerDetails)
  const { provider } = providerDetails

  const orderServiceRender = useSelector((state) => state.orderServiceRender)
  const { loading: loadingRender, success: successRender } = orderServiceRender

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!order._id || order._id !== orderId || successRender) {
      dispatch(getProviderOrderDetails(provider._id, orderId))
    }
  }, [history, dispatch, orderId, successRender])

  if (!loading) {
    order.itemsPrice = order.itemsPrice = order.orderItems.reduce(
      (acc, item) => acc + item.price * item.qty,
      0
    )
    order.taxPrice = order.itemsPrice * 0.15
    order.totalPrice = order.itemsPrice + order.taxPrice
  }

  const renderHandler = (providerId, serviceId) => {
    dispatch(renderOrderService(orderId, providerId, serviceId))
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h1>Order {order._id}</h1>
          <Row>
            <Col md={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Billing</h2>
                  <p>
                    <strong>Name: </strong> {order.user.firstName}{' '}
                    {order.user.lastName}
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <a href={`mailto:${order.user.email}`}>
                      {order.user.email}
                    </a>
                  </p>
                  <p>
                    <p>
                      <strong>Address: </strong>
                    </p>
                    {order.billingAddress.address1}
                    {`\n`}
                    {order.billingAddress.city},{`\n`}
                    {order.billingAddress.postalCode}
                    {`\n`}
                    {order.billingAddress.country}
                  </p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Payment Method</h2>
                  <p>
                    <strong>Method: </strong>
                    {order.paymentMethod}
                  </p>
                  {order.isPaid ? (
                    <Message variant="success">
                      Paid on {order.paidAt.substring(0, 10)}
                    </Message>
                  ) : (
                    <Message variant="danger">Not Paid</Message>
                  )}
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Order Items: </h2>
                  {order.orderItems.length === 0 ? (
                    <Message>Order is empty.</Message>
                  ) : (
                    <ListGroup variant="flush">
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col>
                              <Link to={`/product/${item.product}`}>
                                {item.name}
                              </Link>
                            </Col>
                            <Col></Col>
                            <Col>
                              {item.qty} x ${item.price} = $
                              {item.qty * item.price}
                            </Col>
                          </Row>

                          {userInfo &&
                            userInfo.isProviderAdmin &&
                            order.isPaid &&
                            !item.isRendered && (
                              <Button
                                type="button"
                                className="btn btn-block"
                                onClick={() =>
                                  renderHandler(item.provider, item._id)
                                }
                              >
                                Mark as Rendered
                              </Button>
                            )}
                          {item.isRendered && (
                            <Message variant="success">
                              Rendered on {item.renderedAt.substring(0, 10)}
                            </Message>
                          )}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Order Summary</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Items</Col>
                      <Col>{Currency.format(order.itemsPrice)}</Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Tax</Col>
                      <Col>{Currency.format(order.taxPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Total</Col>
                      <Col>{Currency.format(order.totalPrice)}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ProviderOrderDetailsScreen
