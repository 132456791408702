import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {
  getUserDetails,
  updateUser,
  addProviderAdmin,
  deleteProviderAdmin,
} from '../../actions/userActions'
import { USER_UPDATE_RESET } from '../../constants/userConstants'
import {
  USER_ADD_PROVIDER_ADMIN_RESET,
  USER_DELETE_PROVIDER_ADMIN_RESET,
} from '../../constants/userConstants'

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [provider, setProvider] = useState('')
  const [isProviderAdmin, setIsProviderAdmin] = useState([])

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  const userAddProviderAdmin = useSelector(
    (state) => state.userAddProviderAdmin
  )
  const {
    error: errorAddProvider,
    success: successAddProvider,
  } = userAddProviderAdmin

  const userDeleteProviderAdmin = useSelector(
    (state) => state.userDeleteProviderAdmin
  )
  const {
    error: errorDeleteProvider,
    success: successDeleteProvider,
  } = userDeleteProviderAdmin

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push('/admin/userlist')
    } else if (successAddProvider || successDeleteProvider) {
      setProvider('')
      dispatch({ type: USER_DELETE_PROVIDER_ADMIN_RESET })
      dispatch({ type: USER_ADD_PROVIDER_ADMIN_RESET })
      dispatch(getUserDetails(userId))
    } else {
      if (!user.firstName || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
        setIsProviderAdmin(user.isProviderAdmin)
      }
    }
  }, [
    dispatch,
    history,
    userId,
    user,
    successUpdate,
    successAddProvider,
    successDeleteProvider,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUser({
        _id: userId,
        firstName,
        lastName,
        email,
        isAdmin,
        isProviderAdmin,
      })
    )
  }

  const addProviderAdminHandler = (e) => {
    e.preventDefault()
    dispatch(addProviderAdmin(userId, provider))
  }

  const deleteHandler = (providerId) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteProviderAdmin(userId, providerId))
    }
  }

  return (
    <>
      <Link to="/admin/userlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit User</h1>
        {successAddProvider && <Message>Provider Added</Message>}
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h2>User Info</h2>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="isAdmin">
                <Form.Check
                  type="checkbox"
                  label="Is Master Admin"
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <Button type="submit" variant="primary">
                Save User
              </Button>

              {isProviderAdmin.length > 0 && (
                <>
                  <h2 className="mt-5">Provider Admin</h2>
                  <Table striped bordered hover responsive className="table-sm">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>ID</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isProviderAdmin.map((x) => (
                        <tr key={x._id}>
                          <td>{x.provider.name}</td>
                          <td>{x.provider._id}</td>
                          <td>
                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => deleteHandler(x._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Form>

            <Form onSubmit={addProviderAdminHandler}>
              <Form.Group controlId="isProviderAdmin">
                <Form.Label>Add Provider Admin</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Provider ID"
                  value={provider}
                  onChange={(e) => setProvider(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button type="submit">Add Provider</Button>
            </Form>
          </>
        )}
      </FormContainer>
    </>
  )
}

export default UserEditScreen
