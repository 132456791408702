import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import CPTList from '../../components/CPTList'
import { getProviderDetails } from '../../actions/providerActions'
import { addProviderCPT } from '../../actions/providerActions'
import {
  PROVIDER_ADD_CPT_RESET,
  PROVIDER_DELETE_CPT_RESET,
} from '../../constants/providerConstants'

const AddCPTScreen = ({ match, history }) => {
  const providerId = match.params.id

  const [cpts, setCpts] = useState([])
  const [code, setCode] = useState(0)
  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)

  const dispatch = useDispatch()

  const providerDetails = useSelector((state) => state.providerDetails)
  const { loading, error, provider } = providerDetails

  const providerAddCPT = useSelector((state) => state.providerAddCPT)
  const {
    success: successProviderAddCPT,
    error: errorProviderAddCPT,
  } = providerAddCPT

  const providerDeleteCPT = useSelector((state) => state.providerDeleteCPT)
  const {
    success: successProviderDeleteCPT,
    error: errorProviderDeleteCPT,
  } = providerDeleteCPT

  useEffect(() => {
    if (!provider.name || provider._id !== providerId) {
      dispatch(getProviderDetails(providerId))
    } else if (successProviderAddCPT) {
      alert('CPT added')
      setName('')
      setCode(0)
      setPrice(0)
      dispatch({ type: PROVIDER_ADD_CPT_RESET })
      dispatch(getProviderDetails(providerId))
    } else if (successProviderDeleteCPT) {
      alert('CPT removed')
      dispatch({ type: PROVIDER_DELETE_CPT_RESET })
      dispatch(getProviderDetails(providerId))
    } else {
      setCpts(provider.cpts)
    }
  }, [
    dispatch,
    history,
    providerId,
    provider,
    successProviderAddCPT,
    successProviderDeleteCPT,
  ])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      addProviderCPT(providerId, {
        code,
        name,
        price,
      })
    )
  }

  return (
    <>
      <Link
        to={`/admin/providers/${providerId}`}
        className="btn btn-light my-3"
      >
        Go Back
      </Link>
      <h1>CPTs for {provider.name}</h1>
      <h2>Add New CPT</h2>
      {errorProviderAddCPT && (
        <Message variant="danger">{errorProviderAddCPT}</Message>
      )}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="string"
            placeholder="Enter service name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="code">
          <Form.Label>Code</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter CPT code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="price">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            placeholder="100"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary">
          Add CPT
        </Button>
      </Form>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {errorProviderDeleteCPT && (
            <Message variant="danger">{errorProviderDeleteCPT}</Message>
          )}
          <CPTList match={match} cpts={provider.cpts} />
        </>
      )}
    </>
  )
}
export default AddCPTScreen
