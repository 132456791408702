export const PROVIDER_CREATE_REQUEST = 'PROVIDER_CREATE_REQUEST'
export const PROVIDER_CREATE_SUCCESS = 'PROVIDER_CREATE_SUCCESS'
export const PROVIDER_CREATE_FAIL = 'PROVIDER_CREATE_FAIL'
export const PROVIDER_CREATE_RESET = 'PROVIDER_CREATE_RESET'

export const PROVIDER_LIST_REQUEST = 'PROVIDER_LIST_REQUEST'
export const PROVIDER_LIST_SUCCESS = 'PROVIDER_LIST_SUCCESS'
export const PROVIDER_LIST_FAIL = 'PROVIDER_LIST_FAIL'

export const PROVIDER_DETAILS_REQUEST = 'PROVIDER_DETAILS_REQUEST'
export const PROVIDER_DETAILS_SUCCESS = 'PROVIDER_DETAILS_SUCCESS'
export const PROVIDER_DETAILS_FAIL = 'PROVIDER_DETAILS_FAIL'
export const PROVIDER_DETAILS_RESET = 'PROVIDER_DETAILS_RESET'

export const PROVIDER_UPDATE_REQUEST = 'PROVIDER_UPDATE_REQUEST'
export const PROVIDER_UPDATE_SUCCESS = 'PROVIDER_UPDATE_SUCCESS'
export const PROVIDER_UPDATE_FAIL = 'PROVIDER_UPDATE_FAIL'
export const PROVIDER_UPDATE_RESET = 'PROVIDER_UPDATE_RESET'

export const PROVIDER_ADD_CPT_REQUEST = 'PROVIDER_ADD_CPT_REQUEST'
export const PROVIDER_ADD_CPT_SUCCESS = 'PROVIDER_ADD_CPT_SUCCESS'
export const PROVIDER_ADD_CPT_FAIL = 'PROVIDER_ADD_CPT_FAIL'
export const PROVIDER_ADD_CPT_RESET = 'PROVIDER_ADD_CPT_RESET'

export const PROVIDER_DELETE_CPT_REQUEST = 'PROVIDER_DELETE_CPT_REQUEST'
export const PROVIDER_DELETE_CPT_SUCCESS = 'PROVIDER_DELETE_CPT_SUCCESS'
export const PROVIDER_DELETE_CPT_FAIL = 'PROVIDER_DELETE_CPT_FAIL'
export const PROVIDER_DELETE_CPT_RESET = 'PROVIDER_DELETE_CPT_RESET'

export const PROVIDER_TOGGLE_ACTIVE_REQUEST = 'PROVIDER_TOGGLE_ACTIVE_REQUEST'
export const PROVIDER_TOGGLE_ACTIVE_SUCCESS = 'PROVIDER_TOGGLE_ACTIVE_SUCCESS'
export const PROVIDER_TOGGLE_ACTIVE_FAIL = 'PROVIDER_TOGGLE_ACTIVE_FAIL'
export const PROVIDER_TOGGLE_ACTIVE_RESET = 'PROVIDER_TOGGLE_ACTIVE_RESET'
