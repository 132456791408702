import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Container, Row, Col, ListGroup, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Currency from '../../components/Currency'
import FormContainer from '../../components/FormContainer'
import CPTList from '../../components/CPTList'
import { getProviderDetails } from '../../actions/providerActions'
import { listProviderOrders } from '../../actions/orderActions'
import { PROVIDER_DELETE_CPT_RESET } from '../../constants/providerConstants'
import { ORDER_LIST_PROVIDER_RESET } from '../../constants/orderConstants'
import { getUserDetails } from '../../actions/userActions'

const ProviderDetailsScreen = ({ match, history }) => {
  const providerId = match.params.id

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerDetails = useSelector((state) => state.providerDetails)
  const { loading, error, provider } = providerDetails

  const orderListProvider = useSelector((state) => state.orderListProvider)
  const {
    loading: loadingOrders,
    error: errorOrders,
    orders,
  } = orderListProvider

  useEffect(() => {
    if (!provider.name || provider._id !== providerId) {
      dispatch(getProviderDetails(providerId))
      dispatch(listProviderOrders(providerId))
    }
  }, [dispatch, history, providerId, provider, orders, loadingOrders])

  if (orders != null && !loadingOrders) {
    orders.map(
      (order) =>
        (order.totalPrice = order.orderItems.reduce(
          (acc, item) => acc + item.price * item.qty,
          0
        ))
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={7}>
            <h1>Provider Details</h1>
          </Col>
          <Col xs={5} className="ml-auto m-auto">
            {userInfo != null &&
              userInfo.isProviderAdmin.find(
                (x) => x.provider === providerId
              ) && (
                <Link
                  className="btn btn-dark"
                  to={`/admin/providers/${providerId}/edit`}
                >
                  Edit Provider
                </Link>
              )}
          </Col>
        </Row>
      </Container>

      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h2>{provider.name}</h2>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${provider.address1} ${provider.address2} ${provider.city}, ${provider.state} ${provider.zip}`}
                >
                  <Row>
                    <Col>{provider.address1}</Col>
                  </Row>
                  <Row>
                    <Col>{provider.address2}</Col>
                  </Row>
                  <Row>
                    <Col>
                      {provider.city}, {provider.state}
                    </Col>
                  </Row>
                  <Row>
                    <Col>{provider.zip}</Col>
                  </Row>
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={`mailto:${provider.email}`}>{provider.email}</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={`tel:${provider.phone}`}>{provider.phone}</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href={provider.website}>{provider.website}</a>
              </ListGroup.Item>
            </ListGroup>
          </>
        )}
      </Container>
      <CPTList match={match} cpts={provider.cpts} userInfo={userInfo} />
      {userInfo != null &&
        userInfo.isProviderAdmin.find((x) => x.provider === providerId) && (
          <>
            <h2 className="py-2">Orders</h2>

            <Table striped hover responsive className="table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>PAID</th>
                </tr>
              </thead>
              <tbody>
                {orders != null &&
                  orders.map((order) => (
                    <LinkContainer to={`/provideradmin/orders/${order._id}`}>
                      <tr key={order._id}>
                        <td>{order._id}</td>
                        <td>{order.createdAt.substring(0, 10)}</td>
                        <td>{Currency.format(order.totalPrice)}</td>
                        <td>
                          {order.isPaid ? (
                            <i
                              className="fas fa-check"
                              style={{ color: 'green' }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-times"
                              style={{ color: 'red' }}
                            ></i>
                          )}
                        </td>
                      </tr>
                    </LinkContainer>
                  ))}
              </tbody>
            </Table>
          </>
        )}
    </>
  )
}

export default ProviderDetailsScreen
