import { CPT_DETAILS_REQUEST, CPT_DETAILS_SUCCESS, CPT_DETAILS_FAIL } from '../constants/cptConstants'

export const cptDetailsReducer = (
  state = { cpt: {} },
  action
) => {
  switch (action.type) {
    case CPT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case CPT_DETAILS_SUCCESS:
      return { loading: false, cpt: action.payload }
    case CPT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}