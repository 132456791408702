import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  listProviders,
  toggleProviderActive,
} from '../../actions/providerActions'
import { PROVIDER_TOGGLE_ACTIVE_RESET } from '../../constants/providerConstants'

const ProviderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const providerList = useSelector((state) => state.providerList)
  const { loading, error, providers } = providerList

  const providerToggleActive = useSelector(
    (state) => state.providerToggleActive
  )
  const {
    loading: loadingToggleActive,
    error: errorToggleActive,
    success: successToggleActive,
  } = providerToggleActive

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (successToggleActive) {
      dispatch(listProviders())
      dispatch({ type: PROVIDER_TOGGLE_ACTIVE_RESET })
    }
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProviders())
    } else history.push('/login')
  }, [history, dispatch, userInfo, successToggleActive])

  const activateProviderHandler = (providerId) => {
    dispatch(toggleProviderActive(providerId))
  }
  return (
    <>
      <h1>Providers </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>EDIT</th>
              <th>NAME</th>
              <th>CITY</th>
              <th>STATE</th>
              <th>CPTs</th>
              <th>ACTIVE</th>
            </tr>
          </thead>
          <tbody>
            {providers.map((provider) => (
              <tr key={provider._id}>
                <td>
                  <LinkContainer to={`/admin/providers/${provider._id}/edit`}>
                    <Button variant="light" className="btn-sm">
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                </td>
                <td>{provider.name}</td>
                <td>{provider.city}</td>
                <td>{provider.state}</td>
                <td>{provider.cpts.length}</td>
                <td>
                  <BootstrapSwitchButton
                    checked={provider.isActive}
                    onstyle="success"
                    offstyle="danger"
                    onlabel={
                      <i
                        className="fas fa-check"
                        style={{ color: 'white' }}
                      ></i>
                    }
                    offlabel={
                      <i
                        className="fas fa-times"
                        style={{ color: 'white' }}
                      ></i>
                    }
                    onChange={() => activateProviderHandler(provider._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default ProviderListScreen
