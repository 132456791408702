import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap'
import Message from '../../components/Message'
import Currency from '../../components/Currency'
import { addToCart, removeFromCart } from '../../actions/cartActions'

const CartScreen = ({ match, location, history }) => {
  const serviceId = match.params.id
  const qty = location.search ? Number(location.search.split('=')[1]) : 1

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (serviceId) {
      dispatch(addToCart(serviceId, qty))
    }
  }, [dispatch, serviceId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
    history.replace('/cart')
  }

  const checkoutHandler = () => {
    history.push('/login?redirect=billing')
  }

  return (
    <Row>
      <Col lg={8}>
        <h1>Shopping Cart</h1>
        {cartItems.length === 0 ? (
          <Message>
            Your cart is empty <Link to="/">Go Back</Link>
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((x) => (
              <ListGroup.Item key={x.service}>
                <Row>
                  <Col lg={7}>
                    <Link to={`/service/${x.service}`}>
                      <h2>{x.name}</h2>
                    </Link>
                  </Col>
                  <Col>
                    <p>
                      {x.code} - {x.category}
                    </p>
                    <p>{x.description}</p>
                    <p>
                      <Link to={`providers/${x.providerName}`}>
                        {x.providerName}
                      </Link>{' '}
                      - {x.providerCity}, {x.providerState}
                    </p>
                  </Col>
                  <Col lg={2}>
                    <strong>{Currency.format(x.price)}</strong>
                  </Col>
                  <Col lg={2}>
                    <Form.Control
                      as="select"
                      value={x.qty}
                      onChange={(e) =>
                        dispatch(addToCart(x.service, Number(e.target.value)))
                      }
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </Form.Control>
                  </Col>
                  <Col lg={1}>
                    <Button
                      type="button"
                      variant="light"
                      onClick={() => removeFromCartHandler(x.service)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col lg={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                services
              </h2>
              $
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type="button"
                className="btn-block"
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Proceed to Checkout
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  )
}

export default CartScreen
