import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/shopping_screens/HomeScreen'
import CategoryScreen from './screens/shopping_screens/CategoryScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/shopping_screens/CartScreen'
import LoginScreen from './screens/user_screens/LoginScreen'
import RegisterScreen from './screens/user_screens/RegisterScreen'
import ProfileScreen from './screens/user_screens/ProfileScreen'
import BillingScreen from './screens/shopping_screens/BillingScreen'
import PaymentScreen from './screens/shopping_screens/PaymentScreen'
import PlaceOrderScreen from './screens/shopping_screens/PlaceOrderScreen'
import OrderScreen from './screens/shopping_screens/OrderScreen'
import UserListScreen from './screens/admin_screens/UserListScreen'
import UserEditScreen from './screens/admin_screens/UserEditScreen'
import ProductListScreen from './screens/admin_screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/admin_screens/OrderListScreen'
import ProviderListScreen from './screens/provider_screens/ProviderListScreen'
import ProviderEditScreen from './screens/admin_screens/ProviderEditScreen'
import ProviderDetailsScreen from './screens/provider_screens/ProviderDetailsScreen'
import ProviderOrderDetailsScreen from './screens/provider_screens/ProviderOrderDetailsScreen'
import AddCPTScreen from './screens/provider_screens/AddCPTScreen'
import ServiceDetailScreen from './screens/shopping_screens/ServiceDetailScreen'
import ProviderCreateScreen from './screens/provider_screens/ProviderCreateScreen'

const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/billing" component={BillingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route
            path="/admin/providers/:id"
            component={ProviderDetailsScreen}
            exact
          />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/profile" component={ProfileScreen} />
          <Route path="/product/:id" component={ProductScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/admin/userlist" component={UserListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route
            path="/admin/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/provideradmin/orders/:id"
            component={ProviderOrderDetailsScreen}
          />
          <Route
            path="/admin/productlist/:pageNumber"
            component={ProductListScreen}
            exact
          />
          <Route path="/admin/product/:id/edit" component={ProductEditScreen} />
          <Route path="/admin/orderlist" component={OrderListScreen} />
          <Route path="/admin/providers" component={ProviderListScreen} exact />
          <Route
            path="/admin/providers/:id/edit"
            component={ProviderEditScreen}
            exact
          />
          <Route
            path="/admin/providers/:id/edit/cpts"
            component={AddCPTScreen}
            exact
          />
          <Route
            path="/services/c/:category"
            component={CategoryScreen}
            exact
          />
          <Route path="/services/:id" component={ServiceDetailScreen} exact />

          <Route
            path="/providers/:id"
            component={ProviderDetailsScreen}
            exact
          />
          <Route path="/become-a-provider" component={ProviderCreateScreen} />
          <Route path="/" component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
