import {
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_DETAILS_REQUEST,
  PROVIDER_DETAILS_SUCCESS,
  PROVIDER_DETAILS_FAIL,
  PROVIDER_DETAILS_RESET,
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_FAIL,
  PROVIDER_UPDATE_RESET,
  PROVIDER_ADD_CPT_REQUEST,
  PROVIDER_ADD_CPT_SUCCESS,
  PROVIDER_ADD_CPT_FAIL,
  PROVIDER_ADD_CPT_RESET,
  PROVIDER_DELETE_CPT_REQUEST,
  PROVIDER_DELETE_CPT_SUCCESS,
  PROVIDER_DELETE_CPT_FAIL,
  PROVIDER_DELETE_CPT_RESET,
  PROVIDER_CREATE_REQUEST,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_CREATE_FAIL,
  PROVIDER_CREATE_RESET,
  PROVIDER_TOGGLE_ACTIVE_REQUEST,
  PROVIDER_TOGGLE_ACTIVE_SUCCESS,
  PROVIDER_TOGGLE_ACTIVE_FAIL,
  PROVIDER_TOGGLE_ACTIVE_RESET,
} from '../constants/providerConstants'

export const providerListReducer = (state = { providers: [] }, action) => {
  switch (action.type) {
    case PROVIDER_LIST_REQUEST:
      return {
        loading: true,
      }
    case PROVIDER_LIST_SUCCESS:
      return {
        loading: false,
        providers: action.payload,
      }
    case PROVIDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const providerDetailsReducer = (
  state = { provider: { cpts: [] } },
  action
) => {
  switch (action.type) {
    case PROVIDER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PROVIDER_DETAILS_SUCCESS:
      return { loading: false, provider: action.payload }
    case PROVIDER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_DETAILS_RESET:
      return { provider: {} }
    default:
      return state
  }
}

export const providerUpdateReducer = (state = { provider: {} }, action) => {
  switch (action.type) {
    case PROVIDER_UPDATE_REQUEST:
      return { loading: true }
    case PROVIDER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_UPDATE_FAIL: // all fails are basically the same
      return { loading: false, error: action.payload }
    case PROVIDER_UPDATE_RESET:
      return {
        provider: {},
      }
    default:
      return state
  }
}

export const providerAddCPTReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_ADD_CPT_REQUEST:
      return { loading: true }
    case PROVIDER_ADD_CPT_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_ADD_CPT_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_ADD_CPT_RESET:
      return {}
    default:
      return state
  }
}

export const providerDeleteCPTReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_DELETE_CPT_REQUEST:
      return { loading: true }
    case PROVIDER_DELETE_CPT_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_DELETE_CPT_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_DELETE_CPT_RESET:
      return {}
    default:
      return state
  }
}

export const providerCreateReducer = (state = { providerInfo: {} }, action) => {
  switch (action.type) {
    case PROVIDER_CREATE_REQUEST:
      return { loading: true }
    case PROVIDER_CREATE_SUCCESS:
      return { loading: false, success: true, providerInfo: action.payload }
    case PROVIDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const providerToggleActiveReducer = (state = {}, action) => {
  switch (action.type) {
    case PROVIDER_TOGGLE_ACTIVE_REQUEST:
      return { loading: true }
    case PROVIDER_TOGGLE_ACTIVE_SUCCESS:
      return { loading: false, success: true }
    case PROVIDER_TOGGLE_ACTIVE_FAIL:
      return { loading: false, error: action.payload }
    case PROVIDER_TOGGLE_ACTIVE_RESET:
      return {}
    default:
      return state
  }
}
