import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Table,
  ListGroup,
  Container,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  Button,
  Image,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Meta from '../../components/Meta'
import Currency from '../../components/Currency'
import UpperCase from '../../components/UpperCase'
import Location from '../../components/Location'
import Map from '../../components/Map'
import { listServices } from '../../actions/serviceActions'

const CategoryScreen = ({ match }) => {
  const category = match.params.category
  const keyword = match.params.keyword
  const pageNumber = match.params.pageNumber || 1

  const [button, setButton] = useState(1)
  const [userLocation, setUserLocation] = useState({})

  const dispatch = useDispatch()

  const serviceList = useSelector((state) => state.serviceList)
  const { loading, error, services } = serviceList

  const userLogin = useSelector((state) => state.userLogin)
  const { loading: loadingUser, error: errorUser, userInfo } = userLogin

  //geolocation stuff
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }
  function success(pos) {
    const crd = pos.coords

    console.log('Your current position is:')
    console.log(`Latitude : ${crd.latitude}`)
    console.log(`Longitude: ${crd.longitude}`)
    console.log(`More or less ${crd.accuracy} meters.`)
    console.log(crd)
    setUserLocation(crd)
  }

  function locationError(error) {
    console.warn(`ERROR(${error.code}): ${error.message}`)
  }

  useEffect(() => {
    dispatch(listServices(category))

    navigator.geolocation.getCurrentPosition(success, locationError, options)
  }, [dispatch, keyword, pageNumber, userInfo, category])

  serviceList.category = category // storing category in state for back button on service detail page

  const handleButtonClick = (x) => setButton(x)

  return (
    <>
      <Meta />

      <Container fluid expand="lg">
        <Row>
          <Col>
            <Link to="/" className="btn btn-light">
              Go Back
            </Link>
          </Col>
          <Col>
            <ToggleButtonGroup
              className="ml-auto"
              type="radio"
              name="display"
              value={button}
              onChange={handleButtonClick}
            >
              <ToggleButton value={1} variant="secondary">
                <i className="fa fa-list"></i>
              </ToggleButton>
              <ToggleButton value={2} variant="secondary">
                <i className="fa fa-ellipsis-h"></i>
              </ToggleButton>
              <ToggleButton value={3} variant="secondary">
                <i className="fa fa-map-marker"></i>
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      </Container>
      <h1>Available Providers for {UpperCase(category)}</h1>
      {loading || loadingUser ? (
        <Loader />
      ) : error || errorUser ? (
        <Message variant="danger">{error}</Message>
      ) : services.length === 0 ? (
        <Message variant="danger">{'No services available'}</Message>
      ) : (
        <Row>
          {services
            .filter((provider) => provider.isActive)
            .map((provider) =>
              provider.cpts.map((service) =>
                button === 1 ? (
                  <ListGroup variant="flush">
                    <ListGroup.Item key={service._id}>
                      <LinkContainer to={`/services/${service._id}`}>
                        <Row>
                          <Col xs={4}>
                            <Image
                              src={`/images/${category}.jpg`}
                              alt={category}
                              fluid
                              rounded
                            />
                          </Col>
                          <Col xs={8}>
                            <Row>
                              <Link to={`/services/${service._id}`}>
                                {service.name}
                              </Link>
                            </Row>
                            <Row>{Currency.format(service.price)}</Row>
                            <Row>{provider.name}</Row>
                          </Col>
                        </Row>
                      </LinkContainer>
                    </ListGroup.Item>
                  </ListGroup>
                ) : button === 2 ? (
                  <Col md={4}>
                    <LinkContainer to={`/services/${service._id}`}>
                      <Card key={service._id} className="my-3 p-3 rounded">
                        <Card.Img
                          variant="top"
                          src={`/images/${category}.jpg`}
                        />
                        <Card.Body>
                          <Card.Title>{service.name}</Card.Title>
                          <Card.Subtitle>
                            {Currency.format(service.price)}
                          </Card.Subtitle>
                          <Card.Text>{provider.name}</Card.Text>
                          <Button variant="primary">View Service</Button>
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                          <ListGroup.Item>
                            {provider.city}, {provider.state}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            {service.cpt.description.substring(0, 20)}
                          </ListGroup.Item>
                        </ListGroup>
                      </Card>
                    </LinkContainer>
                  </Col>
                ) : (
                  <></>
                )
              )
            )}
          {button === 3 && (
            <Map
              id="myMap"
              options={{
                center: {
                  lat: userLocation.latitude,
                  lng: userLocation.longitude,
                },
                zoom: 8,
              }}
              onMapLoad={(map) => {
                services
                  .filter((provider) => provider.isActive)
                  .map((provider) =>
                    provider.cpts.map(() => {
                      const marker = new window.google.maps.Marker({
                        position: { lat: provider.lat, lng: provider.lng },
                        map: map,
                        title: provider.name,
                      })
                    })
                  )
              }}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default CategoryScreen
