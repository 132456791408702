import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { PROVIDER_CREATE_RESET } from '../../constants/providerConstants'
import { createProvider } from '../../actions/providerActions'
import { addProviderAdmin } from '../../actions/userActions'

const ProviderCreateScreen = ({ match, history }) => {
  const [name, setName] = useState('')
  const [npi, setNPI] = useState('')
  const [contractedNPI, setContractedNPI] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [facilityType, setFacilityType] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerCreate = useSelector((state) => state.providerCreate)
  const { loading, error, success } = providerCreate

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=become-a-provider')
    }
    if (success) {
      dispatch(addProviderAdmin(userInfo._id, providerCreate.providerInfo._id))
      dispatch({ type: PROVIDER_CREATE_RESET })
      history.push(`/providers/${providerCreate.providerInfo._id}`)
    }
  }, [dispatch, history, success, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProvider({
        name,
        npi,
        contractedNPI,
        address1,
        address2,
        city,
        state,
        zip,
        email,
        phone,
        website,
        facilityType,
      })
    )
  }
  return (
    <>
      <Link to={`/`} className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Become a Provider</h1>
        <p>
          Please complete the requested fields and your application to become a
          provider will be reviewed.
        </p>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Practice Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="npi">
              <Form.Label>NPI</Form.Label>
              <Form.Control
                type="npi"
                placeholder="Enter NPI"
                value={npi}
                onChange={(e) => setNPI(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="contractedNPI">
              <Form.Label>Contracted NPI</Form.Label>
              <Form.Control
                type="contractedNPI"
                placeholder="Enter Contracted NPI"
                value={contractedNPI}
                onChange={(e) => setContractedNPI(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="address1">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="address1"
                placeholder="Enter Address 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="address2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="address2"
                placeholder="Enter Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="city"
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="state"
                placeholder="Enter State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="zip"
                placeholder="Enter Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter practice email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Enter Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="website">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="website"
                placeholder="Enter practice website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="facilityType">
              <Form.Label>Facility Type</Form.Label>
              <Form.Control
                type="facilityType"
                placeholder="Enter Facility Type"
                value={facilityType}
                onChange={(e) => setFacilityType(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {error && <Message variant="danger">{error}</Message>}
            <Button type="submit" variant="primary">
              Register
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProviderCreateScreen
