import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {
  getProviderDetails,
  updateProvider,
} from '../../actions/providerActions'
import { PROVIDER_UPDATE_RESET } from '../../constants/providerConstants'

const ProviderEditScreen = ({ match, history }) => {
  const providerId = match.params.id

  const [name, setName] = useState('')
  const [npi, setNPI] = useState('')
  const [contractedNPI, setContractedNPI] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [facilityType, setFacilityType] = useState('')
  const [cpts, setCpts] = useState([])

  const dispatch = useDispatch()

  const providerDetails = useSelector((state) => state.providerDetails)
  const { loading, error, provider } = providerDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const providerUpdate = useSelector((state) => state.providerUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = providerUpdate

  useEffect(() => {
    if (
      successUpdate ||
      !userInfo.isProviderAdmin.some((x) => x.provider === providerId)
    ) {
      dispatch({ type: PROVIDER_UPDATE_RESET })
      history.push(`/providers/${providerId}`)
    } else {
      if (!provider.name || provider._id !== providerId) {
        dispatch(getProviderDetails(providerId))
      } else {
        setName(provider.name)
        setNPI(provider.npi)
        setContractedNPI(provider.contractedNPI)
        setAddress1(provider.address1)
        setAddress2(provider.address2)
        setCity(provider.city)
        setState(provider.state)
        setZip(provider.zip)
        setEmail(provider.email)
        setPhone(provider.phone)
        setWebsite(provider.website)
        setFacilityType(provider.facilityType)
        setCpts(provider.cpts)
      }
    }
  }, [dispatch, history, providerId, provider, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProvider({
        _id: providerId,
        name,
        npi,
        contractedNPI,
        address1,
        address2,
        city,
        state,
        zip,
        email,
        phone,
        website,
        facilityType,
      })
    )
  }
  return (
    <>
      <Link to={`/providers/${providerId}`} className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Provider Details</h1>
        {loadingUpdate && <Loader />}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="npi">
              <Form.Label>NPI</Form.Label>
              <Form.Control
                type="npi"
                placeholder="Enter NPI"
                value={npi}
                onChange={(e) => setNPI(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="contractedNPI">
              <Form.Label>Contracted NPI</Form.Label>
              <Form.Control
                type="contractedNPI"
                placeholder="Enter Contracted NPI"
                value={contractedNPI}
                onChange={(e) => setContractedNPI(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="address1">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="address1"
                placeholder="Enter Address 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="address2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="address2"
                placeholder="Enter Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="city"
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="state"
                placeholder="Enter State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                type="zip"
                placeholder="Enter Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Enter Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="website">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="website"
                placeholder="Enter website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="facilityType">
              <Form.Label>Facility Type</Form.Label>
              <Form.Control
                type="facilityType"
                placeholder="Enter Facility Type"
                value={facilityType}
                onChange={(e) => setFacilityType(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="cpts">
              <Row>
                <Col>
                  <Form.Label>CPT ID</Form.Label>
                </Col>
                <Col>
                  <Form.Label>CPT PRICE</Form.Label>
                </Col>
              </Row>
              {cpts.map((cpt) => (
                <Row>
                  <Col>
                    <Form.Control
                      type="cpt"
                      placeholder="Enter CPT"
                      value={cpt.cpt.code}
                      readOnly
                    ></Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      type="price"
                      placeholder="Enter Price"
                      value={`$ ${cpt.price}`}
                      readOnly
                    ></Form.Control>
                  </Col>
                </Row>
              ))}
              <Link to={`/admin/providers/${providerId}/edit/cpts`}>
                Edit CPTs
              </Link>
            </Form.Group>
            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default ProviderEditScreen
