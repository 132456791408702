import React from 'react'
import { Table, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch } from 'react-redux'
import Currency from './Currency'
import { deleteProviderCPT } from '../actions/providerActions'

const CPTList = ({ match, cpts, userInfo }) => {
  const dispatch = useDispatch()

  const deleteHandler = (cptId) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteProviderCPT(match.params.id, cptId))
    }
  }

  return (
    <>
      <h2 className="pt-5">CPTs Offered</h2>
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Category</th>
            <th>Description</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cpts.map((cpt) => (
            <LinkContainer to={`/services/${cpt._id}`}>
              <tr key={cpt._id}>
                <td>{cpt.name}</td>
                <td>{cpt.cpt.code}</td>
                <td>{cpt.cpt.category}</td>
                <td>{cpt.cpt.description.substring(0, 30)}</td>
                <td>{Currency.format(cpt.price)}</td>
                {userInfo != null && userInfo.isProviderAdmin.length > 0 && (
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(cpt._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                )}
              </tr>
            </LinkContainer>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default CPTList
