import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productCreateReviewReducer,
  productTopRatedReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userAddProviderAdminReducer,
  userDeleteProviderAdminReducer,
} from './reducers/userReducers'

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
  orderListProviderReducer,
  orderDetailsProviderReducer,
  orderServiceRenderReducer,
} from './reducers/orderReducers'

import {
  providerListReducer,
  providerDetailsReducer,
  providerUpdateReducer,
  providerAddCPTReducer,
  providerDeleteCPTReducer,
  providerRegisterReducer,
  providerCreateReducer,
  providerToggleActiveReducer,
} from './reducers/providerReducers'

import { cptDetailsReducer } from './reducers/cptReducers'

import {
  serviceListReducer,
  serviceDetailsReducer,
} from './reducers/serviceReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productCreateReview: productCreateReviewReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userAddProviderAdmin: userAddProviderAdminReducer,
  userDeleteProviderAdmin: userDeleteProviderAdminReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderDetailsProvider: orderDetailsProviderReducer,
  orderPay: orderPayReducer,
  orderServiceRender: orderServiceRenderReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderListProvider: orderListProviderReducer,
  providerCreate: providerCreateReducer,
  providerList: providerListReducer,
  providerDetails: providerDetailsReducer,
  providerUpdate: providerUpdateReducer,
  providerAddCPT: providerAddCPTReducer,
  providerDeleteCPT: providerDeleteCPTReducer,
  providerToggleActive: providerToggleActiveReducer,
  cptDetails: cptDetailsReducer,
  serviceList: serviceListReducer,
  serviceDetails: serviceDetailsReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const billingAddressFromStorage = localStorage.getItem('billingAddress')
  ? JSON.parse(localStorage.getItem('billingAddress'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    billingAddress: billingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
