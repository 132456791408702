import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import upperCase from '../components/UpperCase'

const Category = ({ category, price, image }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/services/c/${category}`}>
        <Card.Img src={image} variant="top" />
      </Link>
      <Card.Body>
        <Link to={`/services/c/${category}`}>
          <Card.Title as="div">
            <h1>{upperCase(category)}</h1>
          </Card.Title>
        </Link>
        <Card.Text as="p">Starting at: ${price}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Category
