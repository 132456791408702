import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Row,
  Col,
  Button,
  ListGroup,
  Card,
  Form,
  Container,
} from 'react-bootstrap'
import Rating from '../../components/Rating'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Map from '../../components/Map'
import Loader from '../../components/Loader'
import Currency from '../../components/Currency'
import { getServiceDetails } from '../../actions/serviceActions'
import { SERVICE_DETAILS_RESET } from '../../constants/serviceConstants'

const ServiceDetailsScreen = ({ match, history }) => {
  const serviceId = match.params.id
  const [qty, setQty] = useState(1)

  const dispatch = useDispatch()

  const serviceList = useSelector((state) => state.serviceList)
  const { category } = serviceList

  const serviceDetails = useSelector((state) => state.serviceDetails)
  const { loading, error, provider } = serviceDetails

  useEffect(() => {
    dispatch({ type: SERVICE_DETAILS_RESET })
    dispatch(getServiceDetails(serviceId))
  }, [dispatch, history, serviceId])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  if (loading === false) {
    // grab desired service
    serviceDetails.service = provider.cpts.find((x) => x._id === serviceId)
  }
  return (
    <>
      <Link to={`/services/c/${category}`} className="btn btn-light my-3">
        Go Back
      </Link>
      <Container>
        <h1>Service Details</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Row>
              <Col>
                {serviceDetails.service && (
                  <ListGroup variant="flush">
                    <h2>{serviceDetails.service.name}</h2>
                    <ListGroup.Item>
                      Category: {serviceDetails.service.cpt.category}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Description: {serviceDetails.service.cpt.description}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Price: {Currency.format(serviceDetails.service.price)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </Col>
              <Col lg={3}>
                <Card className="my-4">
                  <Button
                    onClick={addToCartHandler}
                    className="btn-block"
                    type="button"
                  >
                    Add to Cart
                  </Button>
                </Card>
              </Col>
            </Row>

            <LinkContainer to={`/providers/${provider._id}`}>
              <ListGroup variant="flush" className="pt-5">
                <h2>Provider Details</h2>
                <ListGroup.Item>
                  <Row>
                    <Col>{provider.name}</Col>
                    <Col>
                      <Rating
                        value={provider.rating}
                        text={`${provider.numReviews}`}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${provider.address1} ${provider.address2} ${provider.city}, ${provider.state} ${provider.zip}`}
                  >
                    <Row>
                      <Col>{provider.address1}</Col>
                    </Row>
                    <Row>
                      <Col>{provider.address2}</Col>
                    </Row>
                    <Row>
                      <Col>
                        {provider.city}, {provider.state}
                      </Col>
                    </Row>
                    <Row>
                      <Col>{provider.zip}</Col>
                    </Row>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href={`tel:+1${provider.phone}`}>{provider.phone}</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <a href={provider.website}>{provider.website}</a>
                </ListGroup.Item>
              </ListGroup>
            </LinkContainer>

            <Map
              id="myMap"
              options={{
                center: { lat: provider.lat, lng: provider.lng },
                zoom: 14,
              }}
              onMapLoad={(map) => {
                var marker = new window.google.maps.Marker({
                  position: { lat: provider.lat, lng: provider.lng },
                  map: map,
                  title: provider.name,
                })
              }}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default ServiceDetailsScreen
