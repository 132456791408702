import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_BILLING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants'

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/services/${id}`)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      name: data.cpts[0].name,
      service: data.cpts[0]._id,
      price: data.cpts[0].price,
      code: data.cpts[0].cpt.code,
      cpt: data.cpts[0].cpt._id,
      category: data.cpts[0].cpt.category,
      description: data.cpts[0].cpt.description,
      providerName: data.name,
      providerAddress1: data.address1,
      providerAddress2: data.address2,
      providerCity: data.city,
      providerState: data.state,
      providerZip: data.zip,
      provider: data._id,
      qty: qty,
    },
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveBillingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_BILLING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('billingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
