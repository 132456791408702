import axios from 'axios'
import {
  PROVIDER_LIST_REQUEST,
  PROVIDER_LIST_SUCCESS,
  PROVIDER_LIST_FAIL,
  PROVIDER_DETAILS_REQUEST,
  PROVIDER_DETAILS_SUCCESS,
  PROVIDER_DETAILS_FAIL,
  PROVIDER_UPDATE_REQUEST,
  PROVIDER_UPDATE_SUCCESS,
  PROVIDER_UPDATE_FAIL,
  PROVIDER_ADD_CPT_REQUEST,
  PROVIDER_ADD_CPT_SUCCESS,
  PROVIDER_ADD_CPT_FAIL,
  PROVIDER_DELETE_CPT_REQUEST,
  PROVIDER_DELETE_CPT_SUCCESS,
  PROVIDER_DELETE_CPT_FAIL,
  PROVIDER_CREATE_REQUEST,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_CREATE_FAIL,
  PROVIDER_TOGGLE_ACTIVE_REQUEST,
  PROVIDER_TOGGLE_ACTIVE_SUCCESS,
  PROVIDER_TOGGLE_ACTIVE_FAIL,
} from '../constants/providerConstants'
import { logout } from './userActions'

export const listProviders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/providers`, config)

    dispatch({
      type: PROVIDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProviderDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PROVIDER_DETAILS_REQUEST,
    })

    const { data } = await axios.get(`/api/providers/${id}`)

    dispatch({
      type: PROVIDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROVIDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProvider = (provider) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/providers/${provider._id}`,
      provider,
      config
    )

    dispatch({
      type: PROVIDER_UPDATE_SUCCESS,
    })
    dispatch({ type: PROVIDER_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PROVIDER_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const addProviderCPT = (providerId, cpt) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PROVIDER_ADD_CPT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/providers/${providerId}/cpts`, cpt, config)

    dispatch({
      type: PROVIDER_ADD_CPT_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PROVIDER_ADD_CPT_FAIL,
      payload: message,
    })
  }
}

export const deleteProviderCPT = (providerId, cptId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PROVIDER_DELETE_CPT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/providers/${providerId}/cpts/${cptId}`, {}, config)

    dispatch({
      type: PROVIDER_DELETE_CPT_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PROVIDER_DELETE_CPT_FAIL,
      payload: message,
    })
  }
}

export const createProvider = (provider) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/providers', provider, config)

    dispatch({
      type: PROVIDER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PROVIDER_CREATE_FAIL,
      payload: message,
    })
  }
}

export const toggleProviderActive = (providerId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PROVIDER_TOGGLE_ACTIVE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/providers/${providerId}/activate`,
      {},
      config
    )

    dispatch({
      type: PROVIDER_TOGGLE_ACTIVE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PROVIDER_TOGGLE_ACTIVE_FAIL,
      payload: message,
    })
  }
}
